import React, { useState, useEffect, useContext } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { globalHistory } from '@reach/router'
import styles from './nav-bar.module.css'
import Logo from '../images/logo.inline.svg'

import InternalLink from './internalLink'
import InternalOrExternalLink from './internalOrExternalLink'

const MenuItem = ({ text, url, internalLink, className, subMenuItems = undefined }) => {
  return (
    <>
      {internalLink && (
        <InternalLink reference={internalLink.reference} className={className}>{text}</InternalLink>
      )}

      {!internalLink && (
        <InternalOrExternalLink to={url} className={className}>{text}</InternalOrExternalLink>
      )}

      {subMenuItems && subMenuItems.length > 0 && (
        <div className='flex flex-col'>
          {subMenuItems.map((item, i) => (
            <MenuItem {...item} key={i} className='text-base mb-2 inline-block no-underline' />
          ))}
        </div>
      )}
    </>
  )
}

const NavBar = ({ path }) => {
  const siteResult = useStaticQuery(
    graphql`
      {
        site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
          ...MainMenuServices
          ...MainMenuInformation
        }
      }
    `
  )

  const [menuActive, setMenuActive] = useState(false)

  // Close main menu when navigating
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' || action === 'POP') {
        setMenuActive(false)
      }
    })
  }, [])

  // Close main menu when clicking ESC key
  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setMenuActive(false)
    }
  }

  useEffect(() => {
    if (menuActive) {
      window.addEventListener('keydown', escFunction)
    } else {
      window.removeEventListener('keydown', escFunction)
    }
  }, [menuActive])

  

  return (
    <div className='absolute w-full'>
      <div className={styles.navBar}>
        <Link to='/' className="mr-auto">
          <Logo className={`text-primary ${styles.logo}`} />
        </Link>


        <button
          className={`${styles.hamburger} ${menuActive ? styles.hamburgerActive : ''}`}
          type='button'
          onClick={(e) => setMenuActive(!menuActive)}
        >
          <span className={styles.hamburgerText}>{menuActive ? 'Lukk' : 'Meny'}</span>
          <span className={styles.hamburgerBox}>
            <span className={styles.hamburgerInner}></span>
          </span>
        </button>
      </div>

      <nav className={`z-30 absolute top-0 left-0 w-full h-screen bg-white flex-col ${menuActive ? 'flex' : 'hidden'}`}>
        {siteResult.site.mainMenuServices && (
          <div className='bg-primary-background p-5 pt-20 md:px-20 md:pt-40'>
            <h4 className='font-bold text-primary text-xs uppercase mb-5'>Tjenester</h4>

            <div className='flex flex-col lg:flex-row lg:flex-wrap'>
              {siteResult.site.mainMenuServices.map((item, i) => (
                <div key={i} className='md:w-1/4 mb-4 md:mb-5 md:pr-8'>
                  <MenuItem {...item} className='font-serif h3 mb-2 md:mr-4 inline-block'  />
                </div>
              ))}
            </div>
          </div>
        )}

        {siteResult.site.mainMenuInformation && (
          <div className='p-5 py-8 md:p-20 flex-auto bg-primary-background-lighter'>
            <h4 className='font-bold text-primary text-xs uppercase mb-5'>Informasjon</h4>

            <div className='flex flex-col md:flex-row md:flex-wrap'>
              {siteResult.site.mainMenuInformation.map((item, i) => (
                <div key={i}>
                  <MenuItem {...item} className='lead mb-2 md:mr-4 inline-block no-underline whitespace-no-wrap' />
                </div>
              ))}
            </div>
          </div>
        )}
      </nav>
    </div>
  )
}

export default NavBar
