import React from 'react'
import Img from 'gatsby-image'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../client-config'

export default ({
  node,
  imageClassName,
  aspectRatio,
  hideCaption,
  ...props
}) => {
  if (!node.asset) {
    return null
  }

  var img
  const imageProps = {
    alt: node.alt || '',
    className: imageClassName || ''
  }

  // For SVGs we don't do anything
  const src = node.asset.url || (node.asset.fixed && node.asset.fixed.src) || (node.asset.fluid && node.asset.fluid.src)

  if (src && src.split('.').pop() === 'svg') {
    img = <img src={src} {...imageProps} />
  } else {
    // Fluid image from graphql
    if (typeof node.asset.fluid !== 'undefined') {
      imageProps.sizes = node.asset.fluid
    }

    // Fixed image from graphql
    else if (typeof node.asset.fixed !== 'undefined') {
      imageProps.sizes = node.asset.fixed
    }

    // If we only have a Sanity reference, we create the fluid image
    else {
      imageProps.sizes = getFluidGatsbyImage(node.asset._ref || node.asset._id, {maxWidth: 1280}, clientConfig.sanity)
    }

    // If an aspect ratio is set
    if (typeof aspectRatio !== 'undefined') {
      imageProps.sizes.aspectRatio = aspectRatio
    }

    img = <Img {...imageProps} />
  }

  return (
    <figure {...props}>
      {img}
      {node.caption && !hideCaption && (
        <figcaption className='mt-5'>{node.caption}</figcaption>
      )}
    </figure>
  )
}
