import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BlockContent from './block-content'

const TopBanner = () => {
  const siteResult = useStaticQuery(
    graphql`
      {
        site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
          banner: _rawHeaderBanner(resolveReferences:{maxDepth:100})
        }
      }
    `
  )

  return (
    <>
      {siteResult.site.banner && (
        <div className='bg-primary text-xs md:text-sm px-5 py-2 z-30 relative'>
          <div className='max-w-screen-md mx-auto text-center text-yellow-lighter link-inherit'>
            <BlockContent blocks={siteResult.site.banner} />
          </div>
        </div>
      )}
    </>
  )
}

export default TopBanner
