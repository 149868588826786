import React from 'react'
import Figure from './figure'
import BlockContent from './block-content'
import Button from './button'
import { TrackedLink } from '../components/enhanced-ecommerce'
import HyphenatedTitle from './hyphenatedTitle'

const ProductCard = ({
  product,
  className,
  ...props
}) => {
  const dataLayer = {
    'event': 'productClick',
    'ecommerce': {
      'click': {
        'actionField': {'list': 'Search Results'},
        'products': [{
          'name': product.title,
          'id': product.id,
        }]
      }
    }
  }

  return (
    <TrackedLink
      to={`/${product.category.slug.current}/${product.slug.current}`}
      dataLayer={dataLayer}
      className={`
        inline-block
        no-underline
        bg-peach-lighter
        p-12
        ${className || ''}
        flex
        flex-col
      `}
      {...props}
    >
      <div className='bg-peach-light w-24 h-24 lg:w-32 lg:h-32 mx-auto mb-6 rounded-full overflow-hidden'>
        {product.images && product.images[0] && <Figure className='w-24 lg:w-32' aspectRatio={1} node={product.images[0]} />}
      </div>

      <h3 className='h4 mb-2 font-serif text-primary'>
        <HyphenatedTitle text={product.title} />
      </h3>

      {product.lead && <BlockContent className='paragraph-4' blocks={product.lead} />}

      <div className='pt-8 mt-auto text-center'>
        <Button color='green' className='mb-4'>Les mer her</Button>

        {product.defaultPrice <= 0 ? (
          <div className='text-primary font-semibold'>Gratis</div>
        ) : (
          <div className='text-primary font-semibold'>Kun {product.defaultPrice},–</div>
        )}
      </div>
    </TrackedLink>
  )
}

export default ProductCard
