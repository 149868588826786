import React, { useState } from 'react'
import {
  useStaticQuery,
  graphql,
  Link
} from 'gatsby'

import InternalLink from '../components/internalLink'
import InternalOrExternalLink from '../components/internalOrExternalLink'
import BlockContent from '../components/block-content'
import Figure from '../components/figure'
import Logo from '../images/logo.inline.svg'
import Button from '../components/button'
import styles from '../components/footer.module.css'

import BankID from '../images/bankid.inline.svg'
import UiO from '../images/uio.inline.svg'
import InnovasjonNorge from '../images/innovasjon-norge.inline.svg'
import Forskningsradet from '../images/forskningsradet.inline.svg'

import Visa from '../images/visa.inline.svg'
import MasterCard from '../images/mastercard.inline.svg'
import Vipps from '../images/vipps.inline.svg'

import ChatIcon from '../images/chat.inline.svg'
import FaqIcon from '../images/faq.inline.svg'

export default () => {
  const siteSettings = useStaticQuery(
    graphql`
      {
        sanitySiteSettings {
          ...MainMenuServices
          ...MainMenuInformation
          footer {
            top: _rawTop(resolveReferences: {maxDepth: 5})
            menu: _rawMenu(resolveReferences: {maxDepth: 5})
            bottom: _rawBottom(resolveReferences: {maxDepth: 5})
            social {
              _key
              url
              image {
                asset {
                  id
                  fluid(maxWidth: 64) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const data = siteSettings && siteSettings.sanitySiteSettings
  const footer = data && data.footer

  // Duplicate code, did not see a way of using the accordion 🤔
  // Should probably rewrite it to not be faq spesific
  const AccordionItem = ({ title, content, ...props }) => {
    const [isOpen, setIsOpen] = useState(props.isOpen || false);

    const toggleAccordionItem = () => {
      setIsOpen(!isOpen)
    }

    return (
      <div className={`lead ${isOpen ? '' : 'mb-4'}`}>
        <button className={`${isOpen ? 'expanded' : ''} flex w-full align-top justify-between text-left`} onClick={toggleAccordionItem}>
          <h3 className={`lead ${isOpen ? 'underline' : ''}`}>{title}</h3>

          <span className='font-mono text-3xl leading-none pl-12'>{isOpen ? '-' : '+'}</span>
        </button>

        <div className={`pt-2 pb-6 paragraph-4 ${isOpen ? '' : 'hidden'}`}>
          {content}
        </div>
      </div>
    )
  }

  const showIntercom = (e) => {
    e.preventDefault()

    if (typeof window.Intercom !== 'undefined') {
      window.Intercom('show')
    }
  }

  const MenuItem = ({ item, children, ...props }) => {
    return (
      <>
        {item.internalLink && (
          <InternalLink reference={item.internalLink.reference} {...props}>{children}</InternalLink>
        )}

        {!item.internalLink && (
          <InternalOrExternalLink to={item.url} {...props}>{children}</InternalOrExternalLink>
        )}
      </>
    )
  }

  return (
    <div className='px-5 py-20 md:px-20 py-20 bg-primary text-white'>
      <div className='max-w-screen-xl mx-auto'>
        <div className='flex justify-between items-center mb-20'>
          <Link to='/'>
            <Logo className={`text-peach-lighter ${styles.logo}`} />
          </Link>
          <div className='flex flex-row'>
            {footer.social.map(social => (
              <a key={social._key} href={social.url} className={styles.socialLink} target="_blank">
                <Figure className='m-3 w-8' node={social.image} />
              </a>
            ))}
          </div>
        </div>
        <div className='grid lg:grid-cols-6 mb-10 md:mb-20'>
          <div className='col-span-6 md:col-span-3 mb-16 lg:mb-0'>
            <div className='p-5 md:p-10 text-text-light bg-peach-lighter rounded-sm'>
              <h2 className='font-serif text-primary mb-5'>Har du spørsmål?</h2>
              <div className='flex flex-wrap md:justify-start mb-5'>
                <Button color='purple' size='small' extraBorder={false} href={'/'} className='mr-2' onClick={showIntercom}>
                  <ChatIcon className='mr-1' style={{ width: '22px' }} />
                  <span>Start chat</span>
                </Button>
                <Button color='purple' size='small' extraBorder={false} href={'/kundeservice'}>
                  <FaqIcon className='mr-1' style={{ width: '22px' }} />
                  <span>Spørsmål og svar</span>
                </Button>
              </div>
              <p className='paragraph-4'>
                Chat med oss mellom kl. 8–16 på arbeidsdager. Du kan alltid sende oss en mail på <a href='mailto:hei@maja.no'>hei@maja.no</a>
              </p>
            </div>
          </div>
          <div className='flex flex-col col-span-6 md:flex-row md:col-span-3'>
            {data.mainMenuServices && (
              <div className='md:mr-20 lg:mr-0 lg:ml-20 flex-1 mb-4 md:mb-0'>
                <h4 className='font-bold text-xs uppercase mb-5'>Tjenester</h4>

                <div className='flex flex-col'>
                  {data.mainMenuServices.map((item, i) => item.subMenuItems.length ? (
                    <AccordionItem key={`service-menu-${i}`} title={item.text} content={item.subMenuItems.map((subMenu, i) => (
                      <div key={`service-menu-subitem-${i}`}>
                        <MenuItem className='mb-2 no-underline inline-block' item={subMenu}>{subMenu.text}</MenuItem>
                      </div>
                    ))}/>
                  ) : (
                    <MenuItem key={`service-menu-${i}`} className='lead mb-4 no-underline inline-block' item={item}>{item.text}</MenuItem>
                  ))}
                </div>
              </div>
            )}

            {data.mainMenuInformation && (
              <div className='md:mr-20 lg:mr-0 lg:ml-20 flex-1'>
                <h4 className='font-bold text-xs uppercase mb-5'>Informasjon</h4>

                <div className='flex flex-col'>
                  {data.mainMenuInformation.map((item, i) => item.subMenuItems.length ? (
                    <AccordionItem key={`information-menu-${i}`} title={item.text} content={item.subMenuItems.map((subMenu, i) => (
                      <div key={`information-menu-subitem-${i}`}>
                        <MenuItem className='lead mb-2 no-underline inline-block' item={subMenu}>{subMenu.text}</MenuItem>
                      </div>
                    ))}/>
                  ) : (
                    <MenuItem key={`information-menu-${i}`} className='lead mb-4 no-underline inline-block' item={item}>{item.text}</MenuItem>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='grid lg:grid-cols-6 mb-20 md:mb-0 text-sm'>
          <div className='col-span-6 md:col-span-3'>
            <div className='grid grid-cols-2 gap-10 items-center mb-20 md:flex md:justify-between'>
              <InnovasjonNorge className='' style={{ width: '113px'}}/>
              <UiO style={{ width: '90px' }} />
              <Forskningsradet style={{ width: '136px' }} />
              <BankID style={{ width: '88px'}} />
            </div>
            <div className='text-sm text-white text-opacity-75'>
              <BlockContent blocks={footer.bottom} />
            </div>
          </div>
          <div className='col-span-6 md:col-span-3'>
            <div className='md:mr-20 lg:mr-0 lg:ml-20 flex-1 mb-4 mb:mb-0'>
              {footer.menu && footer.menu.map(menu => (
                <div key={menu._key}>
                  <ul>
                    {menu.menus.map(item => (
                      <li className='inline-block mr-4 text-white text-opacity-75' key={item._key}>
                        <MenuItem item={item}>{item.text}</MenuItem>
                      </li>
                    ))}
                    <li className='inline-block mr-4 text-white text-opacity-75'><a href="#" data-legalmonster="show-cookie-settings">Administrer informasjonskapsler</a></li>
                  </ul>
                </div>
              ))}
              <div className='flex flex-row mt-10'>
                <Visa className='mr-1' style={{ width: '45px' }} />
                <MasterCard className='mr-1' style={{ width: '45px' }} />
                <Vipps className='mr-1' style={{ width: '43px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
