import React from 'react'
import PropTypes from 'prop-types'
import styles from './button.module.css'

/**
 * A button that allows us to click stuff, usually connected to an `onClick`
 * event, a `href`, or used as a submit button.
 */
const Button = ({
  variant,
  size,
  color,
  rounded,
  children,
  className,
  extraBorder,
  ...props
}) => {
  const classNames = [styles.btn]

  if (className) {
    classNames.push(className)
  }

  // Variant
  if (variant && typeof styles[variant] !== 'undefined') {
    classNames.push(styles[variant])
  }

  // Size
  if (size && typeof styles[size] !== 'undefined') {
    classNames.push(styles[size])
  }

  // Color
  if (color && typeof styles[color] !== 'undefined') {
    classNames.push(styles[color])
  }

  // Rounded
  if (rounded) {
    classNames.push(styles.btnRounded)
  }

  // Extra border
  if (extraBorder) {
    classNames.push(styles.extraBorder)
  }

  return (
    <>
      {props.href && (
        <a className={classNames.join(' ')} {...props}>{children}</a>
      )}

      {!props.href && (
        <button className={classNames.join(' ')} {...props}>{children}</button>
      )}
    </>
  )
}

export default Button

Button.propTypes = {
  variant: PropTypes.oneOf(['fill', 'outline']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['blue', 'purple', 'red', 'green']),
  extraBorder: PropTypes.bool,
  rounded: PropTypes.bool,

  /**
   * Passing a href will transform the button into an anchor element
   */
  href: PropTypes.string
}

Button.defaultProps = {
  variant: 'fill',
  size: 'medium',
  color: 'blue',
  extraBorder: true
}
